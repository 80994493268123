import React from 'react'
import {
  Card,
  CardContent,
  InputAdornment,
  TextField,
} from '@mui/material';
import PaymentList from './payment-list';

const BookingSummary = ({
  formData,
  handleChange,
}) => {

  return (
    <section className="grid-item booking_form__inputs">
    <Card variant="outlined">
      <CardContent>
        <h3 className="booking_form__section__title">Summering</h3>
        <div className="booking_form__section__summary">
          <TextField
            disabled
            name="originalPrice"
            InputProps={{
              endAdornment: <InputAdornment position="end">kr</InputAdornment>,
            }}
            label="Pris för hyra"
            value={Number.isInteger(formData.originalPrice) ? formData.originalPrice : '-'}
            size="small"
            sx={{
              width: '9rem',
            }}
          />

          <TextField
            disabled
            name="addonsTotalPrice"
            InputProps={{
              endAdornment: <InputAdornment position="end">kr</InputAdornment>,
            }}
            label="Pris för tillägg"
            value={formData.addonsTotalPrice}
            size="small"
            sx={{
              width: '9rem',
            }}
          />

          <TextField
            disabled
            name="totalPrice"
            InputProps={{
              endAdornment: <InputAdornment position="end">kr</InputAdornment>,
            }}
            label="Pris inkl tillägg"
            value={Number.isInteger(formData.originalPrice) && Number.isInteger(formData.addonsTotalPrice) ?
              formData.originalPrice + formData.addonsTotalPrice :
              '-'}
            size="small"
            sx={{
              width: '9rem',
            }}
          />

          <TextField
            name="customPrice"
            id="outlined-required"
            onChange={handleChange}
            label="Eget pris"
            InputProps={{
              endAdornment: <InputAdornment position="end">kr</InputAdornment>,
            }}
            size="small"
            value={formData.customPrice}
            sx={{
              width: '9rem'
            }}
          />
        </div>

        {formData.bookingId && (
          <PaymentList bookingId={formData.bookingId} formData={formData} />
        )}
      </CardContent>
    </Card>
  </section>
  )
}

export default BookingSummary;
