import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { TextField, Button, Grid, Typography, Card, CardContent } from '@mui/material';
import { API_URL } from '../../config';
const API_ADMIN_URL = `${API_URL}/admin`;

const fetchAddons = async () => {
  const response = await fetch(`${API_URL}/addons`, {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    },
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Error fetching addons');
  }
  return await response.json();
};

const PropertyAddons = ({ property }) => {
  const queryClient = useQueryClient();
  const propertyId = property.id;
  
  const { 
    data: addons, 
    // isLoading: isAddonsLoading 
  } = useQuery('addons', fetchAddons);
  const [combinedAddons, setCombinedAddons] = useState([]);
  const [originalAddons, setOriginalAddons] = useState([]);

  const upsertPropertyAddonMutation = useMutation(
    async ({ addonId, pricePerUnit }) => {
      const response = await fetch(`${API_ADMIN_URL}/property-addons`, {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          propertyId,
          addonId,
          pricePerUnit: pricePerUnit !== '' ? pricePerUnit : null,
        }),
      });
      if (!response.ok) {
        throw new Error('Error upserting property addon');
      }
    },
    {
      onSuccess: (data, variables) => {
        // Update local state with new values after a successful update
        setCombinedAddons((prevAddons) =>
          prevAddons.map((addon) =>
            addon.addon_id === variables.addonId
              ? {
                ...addon,
                property_specifics: {
                  price_per_unit: variables.pricePerUnit,
                },
              }
              : addon
          )
        );
        queryClient.invalidateQueries(['propertyDetails', { propertyId }]);
      },
    }
  );

  useEffect(() => {
    if (addons) {
      const combined = addons.map((addon) => {
        const propertyAddon = property.addons.find((pa) => pa.addon_id === addon.addon_id);
        return {
          ...addon,
          property_specifics: propertyAddon ? {
            price_per_unit: propertyAddon.price_per_unit,
          } : {
            price_per_unit: '',
          },
        };
      });
      setCombinedAddons(combined);
      setOriginalAddons(JSON.parse(JSON.stringify(combined)));
    } else {
      setCombinedAddons([]);
      setOriginalAddons([]);
    }
  }, [property, addons]);

  const handleUpsertAllAddons = () => {
    combinedAddons.forEach((addon) => {
      if (addon.property_specifics) {
        upsertPropertyAddonMutation.mutate({
          addonId: addon.addon_id,
          pricePerUnit: addon.property_specifics.price_per_unit,
        });
      }
    });
  };

  const handleClearAddonFields = (addonId) => {
    setCombinedAddons((prevAddons) =>
      prevAddons.map((addon) =>
        addon.addon_id === addonId
          ? {
            ...addon,
            property_specifics: {
              price_per_unit: '',
            },
          }
          : addon
      )
    );
  };

  const handleAddonChange = (addonId, field, value) => {
    setCombinedAddons((prevAddons) =>
      prevAddons.map((addon) =>
        addon.addon_id === addonId
          ? {
            ...addon,
            property_specifics: {
              ...addon.property_specifics,
              [field]: value,
            },
          }
          : addon
      )
    );
  };

  return (
    <Card variant="outlined" sx={{ marginTop: '12px' }}>
      <CardContent>
        <Typography variant="h6" marginTop={4}>Tillägg</Typography>
        {combinedAddons.map((addon) => (
          <Grid container spacing={1} key={addon.addon_id} alignItems="center" sx={{
            marginBottom: 1,
          }}>
            <Grid item xs={1}><Typography>{addon.addon_id}</Typography></Grid>
            <Grid item xs={2}><Typography>{addon.name}</Typography></Grid>
            <Grid item xs={2}>
              <TextField
                label="kr / st"
                value={addon.property_specifics && addon.property_specifics.price_per_unit !== undefined && addon.property_specifics.price_per_unit !== null && addon.property_specifics.price_per_unit !== '' ? addon.property_specifics.price_per_unit : addon.price_per_unit}
                onChange={(e) => handleAddonChange(addon.addon_id, 'price_per_unit', e.target.value)}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={2}>
              {addon.property_specifics && addon.property_specifics.price_per_unit !== undefined && addon.property_specifics.price_per_unit !== null && addon.property_specifics.price_per_unit !== '' && (
                <Typography>Anpassad för lägenheten</Typography>
              )}
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleClearAddonFields(addon.addon_id)}
                size='small'
              >
                Rensa
              </Button>
            </Grid>
          </Grid>
        ))}
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpsertAllAddons}
            >
              Spara alla
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PropertyAddons;
