import React, { useState } from 'react'
import {
  TextField,
  Box,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Delete, Edit, Save } from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { API_URL } from '../../config';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';

const API_KEY = process.env.REACT_APP_API_KEY;

const PaymentList = ({ bookingId, formData }) => {
  const queryClient = useQueryClient();
  const [editingId, setEditingId] = useState(null);
  const [editedAmount, setEditedAmount] = useState('');
  const [editedComment, setEditedComment] = useState('');
  const [newAmount, setNewAmount] = useState('');
  const [newComment, setNewComment] = useState('');

  const { data: payments = [] } = useQuery(['booking-payments', bookingId], async () => {
    const res = await fetch(`${API_URL}/booking-payments/booking/${bookingId}`, {
      headers: { 'x-api-key': API_KEY },
      credentials: 'include',
    });
    if (!res.ok) throw new Error('Failed to fetch payments');
    return res.json();
  });

  const addPayment = useMutation(async () => {
    const now = new Date().toISOString().slice(0, 19).replace('T', ' ');
    console.log('Adding payment:', {
      bookingId,
      amount: newAmount,
      comment: newComment,
      paymentDatetime: now,
    });
    await fetch(`${API_URL}/booking-payments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
      credentials: 'include',
      body: JSON.stringify({
        bookingId,
        amount: newAmount,
        comment: newComment,
        paymentDatetime: now,
      }),
    });
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['booking-payments', bookingId]);
      setNewAmount('');
      setNewComment('');
    },
  });

  const updatePayment = useMutation(async (paymentId) => {
    const now = new Date().toISOString().slice(0, 19).replace('T', ' ');
    await fetch(`${API_URL}/booking-payments/${paymentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
      credentials: 'include',
      body: JSON.stringify({
        amount: editedAmount,
        comment: editedComment,
        paymentDatetime: now,
      }),
    });
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['booking-payments', bookingId]);
      setEditingId(null);
    },
  });

  const deletePayment = useMutation(async (paymentId) => {
    await fetch(`${API_URL}/booking-payments/${paymentId}`, {
      method: 'DELETE',
      headers: { 'x-api-key': API_KEY },
      credentials: 'include',
    });
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['booking-payments', bookingId]);
    },
  });

  const totalPaid = payments.reduce((sum, p) => sum + parseFloat(p.amount), 0);
  const totalCost = formData?.customPrice > 0 ?
    formData.customPrice :
    (formData.originalPrice + formData.addonsTotalPrice);

  const diff = totalCost - totalPaid;

  return (
    <Box mt={2}>
      <Typography variant="subtitle1">Betalningar</Typography>

      <Box mt={2} display="flex" gap={2}>
        <TextField
          label="Totalt betalt"
          value={`${totalPaid} kr`}
          disabled
          size="small"
          sx={{ width: '12rem' }}
        />
        <TextField
          label="Kvar att betala"
          value={`${diff} kr`}
          disabled
          size="small"
          sx={{
            width: '12rem',
            label: {
              backgroundColor: 'white',
            },
            input: {
              backgroundColor: diff > 0 ? '#fdd' : '#dfd',
              border: '1px solid',
              borderRadius: '4px',
              borderColor: diff > 0 ? 'red' : 'green',
            }
          }}
        />
      </Box>

      <List>
        {payments.map((payment) => (
          <ListItem key={payment.payment_id} divider>
            {editingId === payment.payment_id ? (
              <>
                <TextField
                  label="Belopp"
                  type="number"
                  value={editedAmount}
                  onChange={(e) => setEditedAmount(e.target.value)}
                  size="small"
                  sx={{ width: '6rem', mr: 1 }}
                />
                <TextField
                  label="Kommentar"
                  value={editedComment}
                  onChange={(e) => setEditedComment(e.target.value)}
                  size="small"
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <IconButton onClick={() => updatePayment.mutate(payment.payment_id)}><Save /></IconButton>
              </>
            ) : (
              <>
                <ListItemText
                  primary={`${payment.amount} kr`}
                  secondary={format(payment.payment_datetime, 'yyyy-MM-dd HH:mm') + ' # ' + payment.comment}
                />
                <IconButton onClick={() => {
                  setEditingId(payment.payment_id);
                  setEditedAmount(payment.amount);
                  setEditedComment(payment.comment || '');
                }}><Edit /></IconButton>
              </>
            )}
            <IconButton onClick={() => deletePayment.mutate(payment.payment_id)}><Delete /></IconButton>
          </ListItem>
        ))}
        <ListItem>
          <TextField
            label="Belopp"
            type="number"
            value={newAmount}
            onChange={(e) => setNewAmount(e.target.value)}
            size="small"
            sx={{ width: '6rem', mr: 1 }}
          />
          <TextField
            label="Kommentar"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            size="small"
            sx={{ flexGrow: 1, mr: 1 }}
          />
          <Button onClick={() => addPayment.mutate()} variant="contained">Spara</Button>
        </ListItem>
      </List>
    </Box>
  );
};

export default PaymentList;
