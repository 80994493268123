import React from 'react'
import PropertyAdmin from './property-admin';
import CustomPrice from './custom-price';
import PropertyAddons from './property-addons';
import { useAdminContext } from '../../context/admin-context'
import { useQuery } from 'react-query';
import { API_URL } from '../../config';
const API_ADMIN_URL = `${API_URL}/admin`;

const fetchPropertyDetails = async ({ queryKey }) => {
  const [, { propertyId }] = queryKey;
  const response = await fetch(`${API_ADMIN_URL}/properties/${propertyId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    },
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Error fetching property details');
  }
  return await response.json();
};

const PropertyDetails = () => {
  const {
    activePropertyId,
  } = useAdminContext();

  const { 
    data: property, 
    // isLoading: isPropertyLoading 
  } = useQuery(['propertyDetails', { propertyId: activePropertyId }], fetchPropertyDetails);

  console.log('property', property);

  return (
    <div className="property-details">
      {property && (
        <>
          <CustomPrice propertyId={property.id} />
          <PropertyAdmin property={property} />
          <PropertyAddons property={property} />
        </>
      )}
    </div>

  )
}

export default PropertyDetails
