import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { Delete, Edit, Save } from '@mui/icons-material';
import { API_URL } from '../../config';

const API_KEY = process.env.REACT_APP_API_KEY;
const API_COMMENTS = `${API_URL}/booking-comments`;

const BookingComponents = ({ bookingId }) => {
  const queryClient = useQueryClient();
  const [editingId, setEditingId] = useState(null);
  const [editedText, setEditedText] = useState('');
  const [newCommentText, setNewCommentText] = useState('');

  const fetchComments = async () => {
    const res = await fetch(`${API_COMMENTS}/${bookingId}`, {
      headers: {
        'x-api-key': API_KEY,
      },
      credentials: 'include',
    });
    if (!res.ok) throw new Error('Failed to fetch comments');
    return res.json();
  };

  const { data: comments = [], isLoading } = useQuery(['comments', bookingId], fetchComments);

  const addComment = useMutation(
    async (text) => {
      const now = new Date().toISOString().slice(0, 19).replace('T', ' ');
      await fetch(API_COMMENTS, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        credentials: 'include',
        body: JSON.stringify({
          bookingId,
          comment: text,
          commentDatetime: now,
        }),
      });
    },
    {
      onMutate: async (text) => {
        await queryClient.cancelQueries(['comments', bookingId]);
        const previousComments = queryClient.getQueryData(['comments', bookingId]);

        const fakeId = Date.now();
        queryClient.setQueryData(['comments', bookingId], (old = []) => [
          ...old,
          {
            comment_id: fakeId,
            booking_id: bookingId,
            comment: text,
            comment_datetime: new Date().toISOString(),
          },
        ]);

        setNewCommentText('');
        return { previousComments };
      },
      onError: (_err, _newComment, context) => {
        if (context?.previousComments) {
          queryClient.setQueryData(['comments', bookingId], context.previousComments);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['comments', bookingId]);
      },
    }
  );

  const updateComment = useMutation(
    async ({ id, comment }) => {
      const now = new Date().toISOString().slice(0, 19).replace('T', ' ');
      await fetch(`${API_COMMENTS}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        credentials: 'include',
        body: JSON.stringify({
          comment,
          commentDatetime: now,
        }),
      });
    },
    {
      onMutate: async ({ id, comment }) => {
        await queryClient.cancelQueries(['comments', bookingId]);
        const previousComments = queryClient.getQueryData(['comments', bookingId]);

        queryClient.setQueryData(['comments', bookingId], (old = []) =>
          old.map((c) =>
            c.comment_id === id ? { ...c, comment } : c
          )
        );

        setEditingId(null);
        setEditedText('');
        return { previousComments };
      },
      onError: (_err, _updated, context) => {
        if (context?.previousComments) {
          queryClient.setQueryData(['comments', bookingId], context.previousComments);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['comments', bookingId]);
      },
    }
  );

  const deleteComment = useMutation(
    async (commentId) => {
      await fetch(`${API_COMMENTS}/${commentId}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': API_KEY,
        },
        credentials: 'include',
      });
    },
    {
      onMutate: async (commentId) => {
        await queryClient.cancelQueries(['comments', bookingId]);
        const previousComments = queryClient.getQueryData(['comments', bookingId]);

        queryClient.setQueryData(['comments', bookingId], (old = []) =>
          old.filter((c) => c.comment_id !== commentId)
        );

        return { previousComments };
      },
      onError: (_err, _id, context) => {
        if (context?.previousComments) {
          queryClient.setQueryData(['comments', bookingId], context.previousComments);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['comments', bookingId]);
      },
    }
  );

  if (isLoading) return <Typography>Laddar kommentarer...</Typography>;

  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <List sx={{ margin: 0, padding: 0 }}>
        {comments.map((comment) => (
          <ListItem key={comment.comment_id} divider sx={{ margin: 0, padding: 0 }}>
            {editingId === comment.comment_id ? (
              <TextField
                fullWidth
                variant="outlined"
                value={editedText}
                onChange={(e) => setEditedText(e.target.value)}
              />
            ) : (
              <ListItemText
                primary={comment.comment}
                secondary={new Date(comment.comment_datetime).toLocaleString()}
              />
            )}
            {editingId === comment.comment_id ? (
              <IconButton
                onClick={() =>
                  updateComment.mutate({
                    id: comment.comment_id,
                    comment: editedText,
                  })
                }
              >
                <Save />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  setEditingId(comment.comment_id);
                  setEditedText(comment.comment);
                }}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton onClick={() => deleteComment.mutate(comment.comment_id)}>
              <Delete />
            </IconButton>
          </ListItem>
        ))}
        {/* Ny kommentar */}
        <ListItem
          sx={{
            marginTop: '12px',
            paddingBottom: 0,
            paddingLeft: 0
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Skriv en ny kommentar..."
            value={newCommentText}
            onChange={(e) => setNewCommentText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => addComment.mutate(newCommentText)}
            disabled={!newCommentText.trim()}
            sx={{ ml: 2 }}
          >
            Spara
          </Button>
        </ListItem>
      </List>
    </Box>
  );
};

export default BookingComponents;