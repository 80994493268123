import PropTypes from "prop-types"
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react'
import { useAdminContext } from '../../context/admin-context'
import { API_URL } from "../../config";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import {
  getSelectedDateFromSelectedWeek,
  getFutureDateFromFutureWeek
} from '../../utils/date-utils';
import clx from '../../utils/clx'
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import BookingAddons from "./booking-addons";
import BookingSummary from "./booking-summary";
import BookingComponents from "../booking-comments/booking-comments";

import './booking-form.css'

function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

const defaultFormData = {
  addonsTotalPrice: 0,
  bookingId: '',
  guestCountry: '',
  guestFirstName: '',
  guestLastName: '',
  guestStreet: '',
  guestPostalNumber: '',
  guestPostalAddress: '',
  guestEmail: '',
  guestPhone: '',
  rentalPropertyId: '',
  startWeek: '',
  description: '',
  startDate: '',
  endDate: '',
  originalPrice: '',
  customPrice: '',
  startYear: '',
}

const fetchPropertyPrice = async (propertyId, startWeek, startYear, numberOfWeeks) => {
  const response = await fetch(`${API_URL}/properties/${propertyId}/available?startWeek=${startWeek}&endWeek=${startWeek + numberOfWeeks - 1}&year=${startYear}&minBeds=1`, {
    credentials: 'include',
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  });
  if (!response.ok) {
    if (response.status === 404) {
      return { total_price: 0 };
    }

    throw new Error('Failed to fetch property price');
  }
  return response.json();
}

const ConfirmDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      // fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Radera bokning
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Är du säker på att du vill radera bokningen?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onConfirm}>
          Ja
        </Button>
        <Button onClick={onClose} autoFocus>
          Avbryt
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * @typedef {Object} BookingFormProps
 * @property {number[]} [numberOfWeekOptions] - Array of available week options.
 * @property {(formData: FormData) => void} [onSubmit] - Function to handle form submission.
 * @property {FormData} [preloadedFormData] - Preloaded form data.
 */

/**
 * Component for booking form.
 * @param {BookingFormProps} props - Props for the BookingForm component.
 * @returns {JSX.Element} - React component.
 */
export default function BookingForm({
  numberOfWeekOptions = [1, 2, 3, 4],
  preloadedFormData
}) {

  // console.log('BookingForm', preloadedFormData);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [isValidForSubmit, setIsValidForSubmit] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [formData, setFormData] = useState({
    ...defaultFormData,
    ...preloadedFormData
  });

  const {
    activeBookingId,
    activeYear,
    activeWeekNumber,
    setActiveState,
    setActiveNumberOfWeeks,
    setActiveBookingId,
    activeNumberOfWeeks,
    reservationsData
  } = useAdminContext();

  const { data, error, refetch: refetchPropertyPrice, isLoading } = useQuery(
    ['propertyPrice', formData.rentalPropertyId, formData.startWeek, formData.startYear, activeNumberOfWeeks],
    () => fetchPropertyPrice(formData.rentalPropertyId, formData.startWeek, formData.startYear, activeNumberOfWeeks),
    {
      enabled: !activeBookingId && !!formData.rentalPropertyId && !!formData.startWeek && !!formData.startYear,
      cacheTime: 300000,
      refetchOnWindowFocus: false
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  // Memoized callback to set the booking possibility
  const checkIfPossibleToBook = useCallback(
    () => {
      let isPossible = true;

      // if the booking is not a block and a real booking
      if (!formData.block && !formData.bookingId) {
        isPossible = Boolean(
          formData.guestFirstName &&
          // formData.guestEmail &&
          // formData.startWeek &&
          // formData.startYear &&
          // formData.rentalPropertyId &&
          // formData.numberOfWeeks &&
          // formData.startDate &&
          // formData.endDate &&
          (formData.originalPrice || formData.customPrice)
        )
      }

      // console.log('IsPossibleToBook?', isPossible);
      setIsValidForSubmit(isPossible);
    },
    [formData]
  );

  const debouncedCheckIfPossibleToBook = useMemo(
    () => debounce(checkIfPossibleToBook, 600), // Adjust the debounce delay as needed
    [checkIfPossibleToBook]
  );

  // Effect to run the debounced check whenever formData changes
  useEffect(() => {
    debouncedCheckIfPossibleToBook();
  }, [debouncedCheckIfPossibleToBook]);

  const {
    addReservation,
    updateReservation,
    deleteReservation
  } = reservationsData;

  const calculatedPrice = useMemo(() => {
    let total = 0;
    if (data && data.total_price) {
      total += parseInt(data.total_price, 10);
    }

    return total;

  }, [data]);

  const onSubmit = async (formData) => {
    // Update booking
    if (formData.bookingId) {
      const { error, data } = await updateReservation(formData);
      if (error) {
        console.error('Error updating booking', error);
        enqueueSnackbar('Fel uppstod vid uppdatering av bokning', { variant: 'error' })

        return;
      }

      enqueueSnackbar('Bokning uppdaterad', { variant: 'success' })
    }

    // Create booking
    else {
      console.log('Create booking', formData);
      const bookingData = {
        ...formData,
        endWeek: formData.startWeek + activeNumberOfWeeks - 1,
        endYear: formData.startYear,
        startDate: format(new Date(formData.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(formData.endDate), 'yyyy-MM-dd'),
        customPrice: formData.customPrice || null
      }
      const { error, data } = await addReservation(bookingData);

      if (error) {
        console.error('Error adding reservation', error);
        enqueueSnackbar('Fel uppstod vid skapande av bokning', { variant: 'error' })
        return;
      }

      setFormData((currentFormData) => ({
        ...currentFormData,
        bookingId: data.id
      }));
      setActiveBookingId(data.id);
      enqueueSnackbar(`Bokning (${data.id}) skapad`, { variant: 'success' })
    }
  }

  useEffect(() => {
    console.log('calculatedPrice changed', calculatedPrice)
    if (calculatedPrice) {
      setFormData((currentFormData) => ({
        ...currentFormData,
        originalPrice: calculatedPrice,
        // customPrice: currentFormData.customPrice || calculatedPrice
      }));
    }
  }, [calculatedPrice])

  useEffect(() => {
    // Check if formData is different from preloadedFormData
    const comparableFields = [
      'block',
      'guestFirstName',
      'guestLastName',
      'guestStreet',
      'guestPostalNumber',
      'guestPostalAddress',
      'guestEmail',
      'guestPhone',
      'guestCountry',
      'customPrice',
      'numberOfWeeks',
      'description',
      'arrivalTime',
      'departureTime',
    ];

    const originalFormData = {
      ...defaultFormData,
      ...preloadedFormData
    };

    let isModified = false;

    for (let field of comparableFields) {

      if (field === 'customPrice') {
        if (originalFormData.customPrice && originalFormData.customPrice !== formData.customPrice) {
          isModified = true;
          break;
        }
      }

      if (formData[field] !== originalFormData[field]) {
        isModified = true;
        break;
      }
    }

    setIsModified(isModified);
  }, [formData, preloadedFormData]);

  // set start and end date when startWeek and startYear are set
  useEffect(() => {
    if (formData.startWeek && formData.startYear) {
      const startDate = getSelectedDateFromSelectedWeek(formData.startYear, formData.startWeek);
      const endDate = getFutureDateFromFutureWeek(formData.startYear, formData.startWeek, formData.startWeek + activeNumberOfWeeks - 1);
      setFormData((currentFormData) => ({
        ...currentFormData,
        startDate,
        endDate
      }));
    }
  }, [formData.startWeek, formData.startYear, activeNumberOfWeeks])

  useEffect(() => {
    setFormData((currentFormData) => ({
      ...defaultFormData,
      ...currentFormData,
      ...preloadedFormData
    }));
  }, [preloadedFormData])

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
  }

  const resetForm = (event) => {
    setFormData({
      ...defaultFormData,
      ...preloadedFormData,
      startDate: getSelectedDateFromSelectedWeek(activeYear, activeWeekNumber),
      endDate: getFutureDateFromFutureWeek(activeYear, activeWeekNumber, activeWeekNumber + activeNumberOfWeeks - 1)
    });
    setActiveNumberOfWeeks(1);
    if (!formData.bookingId) {
      refetchPropertyPrice().then((refetchResponse) => {
        const { data } = refetchResponse;
        if (data.total_price) {
          setFormData((currentFormData) => ({
            ...currentFormData,
            originalPrice: data.total_price,
            customPrice: data.total_price
          }));
        }
      });
    }
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: Boolean(checked)
    });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (name === 'numberOfWeeks') {
      setActiveState({
        activeNumberOfWeeks: value
      })
    }
  }

  const doRemoveBooking = () => {
    deleteReservation(formData.bookingId).then(() => {
      enqueueSnackbar('Bokning raderad', { variant: 'success' });
      setActiveBookingId(null);
      setShowConfirmationDialog(false);
    }).catch((error) => {
      console.error('Error deleting booking', error);
      enqueueSnackbar('Fel uppstod vid radering av bokning', { variant: 'error' });
    });
  }

  const handleRemoveBooking = () => {
    if (formData.bookingId) {
      console.debug('Remove booking', formData.bookingId);
      setShowConfirmationDialog(true);
    }
  };

  console.log('BookingForm', formData);

  return (
    <div className={clx('booking_form', 'grid-container', {
      'booking_form--modified': isModified
    })}>
      <section className="full-width booking_form__buttons">
        <Button
          className="booking_form__button"
          disabled={!isModified || !isValidForSubmit}
          onClick={handleSubmit}
          variant="contained"
        >
          {formData.bookingId ? 'Uppdatera bokning' : 'Skapa bokning'}
        </Button>
        <Button
          className="booking_form__button"
          disabled={!isModified}
          onClick={resetForm}
          variant="outlined"
        >
          Återställ formuläret
        </Button>
        {formData.bookingId && (
          <Button
            className="booking_form__button"
            onClick={handleRemoveBooking}
            variant="outlined"
          >
            Ta bort bokning
          </Button>
        )}
      </section>

      <section className="full-width booking_form__messages">
        {error && <div className="booking_form__message booking_form__message--error">Ett fel uppstod vid hämtning av pris</div>}
        {!formData.bookingId && !isLoading && !error && !calculatedPrice && <div className="booking_form__message">Pris kan ej beräknas. Bokningsperiod kanske ej möjlig</div>}
      </section>

      <section className="grid-item booking_form__inputs">
        <Card variant="outlined">
          <CardContent>
            <h3 className="booking_form__section__title">Bokningsdetaljer</h3>
            <div className="booking_form__section__details">
              {formData.bookingId && (
                <TextField
                  name="bookingId"
                  disabled
                  label="Bokningsnummer"
                  value={formData.bookingId}
                  size="small"
                  sx={{
                    maxWidth: '8rem',
                  }}
                />
              )}
              <TextField
                name="rentalPropertyId"
                disabled
                label="Lägenhet"
                value={formData.rentalPropertyId}
                size="small"
                sx={{
                  maxWidth: '5rem',
                }} />
              <TextField
                disabled
                name="startWeek"
                label="Startvecka"
                value={formData.startWeek}
                size="small"
                sx={{
                  maxWidth: '6rem',
                }} />
              <FormControl
                size="small"
              >
                <InputLabel id="number-of-weeks-label">Antal veckor</InputLabel>
                <Select
                  name="numberOfWeeks"
                  labelId="number-of-weeks-label"
                  disabled={formData.bookingId ? true : false}
                  // TODO: Fix better handling for updating booking number of weeks
                  value={formData.bookingId ? formData.numberOfWeeks : activeNumberOfWeeks}
                  label="Antal veckor"
                  onChange={handleChange}
                >
                  {numberOfWeekOptions.map((option) => (
                    <MenuItem key={option} value={option}>{`${option} veck${option > 1 ? 'or' : 'a'}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl size="small">
                <FormControlLabel control={
                  <Checkbox
                    disabled={formData.bookingId ? true : false}
                    name="block"
                    checked={Boolean(formData.block)}
                    onChange={handleCheckboxChange}
                  />
                } label="Blockera" />
              </FormControl>

              <div className="booking_form__section__details__dates">
                <TextField
                  disabled
                  name="startDate"
                  label="Ankomstdag"
                  size="small"
                  value={formData.startDate ? format(formData.startDate, 'EEE d MMM yyyy', { locale: sv }) : ''}
                />
                <TextField
                  disabled
                  name="endDate"
                  label="Avresedag"
                  size="small"
                  value={formData.endDate ? format(formData.endDate, 'EEE d MMM yyyy', { locale: sv }) : ''}
                />
              </div>

              <div className="booking_form__section__details__dates">
                <TextField
                  name="arrivalTime"
                  label="Ankomsttid"
                  size="small"
                  value={formData.arrivalTime}
                  onChange={handleChange}
                />
                <TextField
                  name="departureTime"
                  label="Avresetid"
                  size="small"
                  value={formData.departureTime}
                  onChange={handleChange}
                />
              </div>

              {/* <TextField
                name="description"
                label="Beskrivning"
                onChange={handleChange}
                value={formData.description}
                size="small"
                sx={{
                  width: '100%'
                }}
              /> */}
            </div>
          </CardContent>
        </Card>
      </section>

      <section className="grid-item booking_form__inputs">
        <Card variant="outlined">
          <CardContent>
            <h3 className="booking_form__section__title">Gäst</h3>
            <div className="booking_form__section__guest">
              <TextField
                required
                name="guestFirstName"
                onChange={handleChange}
                label="Förnamn"
                value={formData.guestFirstName}
                size="small"
                sx={{
                  maxWidth: '12rem',
                }}
              />
              <TextField
                name="guestLastName"
                label="Efternamn"
                onChange={handleChange}
                value={formData.guestLastName}
                size="small"
                sx={{
                  width: '18rem'
                }}
              />
              <TextField
                name="guestStreet"
                onChange={handleChange}
                label="Adress"
                value={formData.guestStreet}
                size="small"
                sx={{
                  width: '18rem'
                }}
              />
              <TextField
                name="guestPostalNumber"
                label="Postnummer"
                onChange={handleChange}
                value={formData.guestPostalNumber}
                size="small"
                sx={{
                  width: '8rem'
                }}
              />
              <TextField
                name="guestPostalAddress"
                label="Ort"
                onChange={handleChange}
                value={formData.guestPostalAddress}
                size="small"
                sx={{
                  width: '10rem'
                }}
              />
              <TextField
                name="guestEmail"
                label="E-post"
                onChange={handleChange}
                value={formData.guestEmail}
                size="small"
                sx={{
                  width: '18rem'
                }}
              />
              <TextField
                name="guestPhone"
                label="Telefon"
                onChange={handleChange}
                value={formData.guestPhone}
                size="small"
              />
              <TextField
                name="guestCountry"
                label="Land"
                onChange={handleChange}
                value={formData.guestCountry}
                size="small"
              />
            </div>
          </CardContent>
        </Card>
      </section>

      {formData.rentalPropertyId && formData.bookingId && (
        <section className="grid-item booking_form__inputs">
          <Card variant="outlined">
            <CardContent>
              <h3 className="booking_form__section__title">Tillägg</h3>
              <BookingAddons propertyId={formData.rentalPropertyId} bookingId={formData.bookingId} />
            </CardContent>
          </Card>
        </section>
      )}

      {formData.rentalPropertyId && formData.bookingId && (
        <section className="grid-item booking_form__inputs">
          <Card variant="outlined">
            <CardContent>
              <h3 className="booking_form__section__title">Kommentarer</h3>

              <BookingComponents bookingId={formData.bookingId} />
            </CardContent>
          </Card>
        </section>
      )}

      {/* Booking-summary */}
      <BookingSummary
        formData={formData}
        handleChange={handleChange}
      />

      <ConfirmDialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirm={doRemoveBooking}
      />
    </div>
  )
}

BookingForm.propTypes = {
  numberOfWeekOptions: PropTypes.arrayOf(PropTypes.number),
  onNumberOfWeeksChange: PropTypes.func,
  onSubmit: PropTypes.func,
  preloadedFormData: PropTypes.shape({
    addonsTotalPrice: PropTypes.number,
    bookingId: PropTypes.number,
    customPrice: PropTypes.number,
    description: PropTypes.string,
    endDate: PropTypes.string,
    guestEmail: PropTypes.string,
    guestFirstName: PropTypes.string,
    guestLastName: PropTypes.string,
    guestPhone: PropTypes.string,
    guestPostalAddress: PropTypes.string,
    guestPostalNumber: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    guestStreet: PropTypes.string,
    numberOfWeeks: PropTypes.number,
    originalPrice: PropTypes.number,
    rentalPropertyName: PropTypes.string,
    rentalPropertyId: PropTypes.number,
    startDate: PropTypes.string,
    startWeek: PropTypes.number,
    startYear: PropTypes.number
  })
};