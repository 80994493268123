import DetailPanel from '../../components/detail-panel/detail-panel';
import MainHeader from '../../components/main-header/main-header';
import RentalPropertiesList from '../../components/rental-properties-list/rental-properties-list';
import WeekMatrix from '../../components/week-matrix/week-matrix';
import Overview from '../../components/overview/overview';
import { useAdminContext } from '../../context/admin-context';
import { VIEWS } from '../../constants';

import './dashboard.css';

const DashBoard = () => {

  const { activeView } = useAdminContext();

  return (
    <div className="dashboard">
      <MainHeader />
      <div className="app__main">
        {activeView === VIEWS.OVERVIEW.value && (
          <Overview />
        )}

        {activeView === VIEWS.RESERVATIONS.value && (
          <>
            <RentalPropertiesList />
            <WeekMatrix />
          </>
        )}

        {activeView === VIEWS.PROPERTIES.value && (
          <>
            <RentalPropertiesList />
            <WeekMatrix />
          </>
        )}
      </div>
      {activeView !== VIEWS.OVERVIEW.value && (
        <DetailPanel />
      )}
    </div>
  )
}

export default DashBoard;