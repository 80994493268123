import React, { useState } from 'react';
import useActiveBooking from '../../hooks/use-active-booking';
import BookingConfirmation from '../booking-confirmation/booking-confirmation';

import BookingForm from '../booking-form/booking-form';
import './detailed-booking-view.css'

export default function DetailedBookingView() {
  const activeBooking = useActiveBooking();

  const [showConfirmation, setShowConfirmation] = useState(false);

  // const availableWeeks = useAvailableWeeks();
  const {
    activeYear,
    activeWeekNumber,
    activePropertyId,
    rentalProperty,
    reservation = {}
  } = activeBooking;

  const { id: bookingId, ...reservationRest } = reservation;

  const preloadedFormData = {
    // block: Boolean(block),
    startYear: activeYear,
    endYear: activeYear,
    bookingId,
    ...reservationRest,
    rentalPropertyName: rentalProperty?.name,
    numberOfWeeks: reservation.endWeek - reservation.startWeek + 1,
  }

  const availableWeeks = Array.from({ length: preloadedFormData.numberOfWeeks }, (_, i) => i + 1);

  return (
    <div className="detailed_booking_view">
      <div className="detailed_booking_view__header">
        <div className="detailed_booking_view__week_number">
          {`Vecka ${activeWeekNumber}`}
        </div>
        <div className="detailed_week_view__property_id">
          {`Lägenhet ${activePropertyId}`}
        </div>
        <button className={showConfirmation ? 'show-confirmation-btn active' : 'show-confirmation-btn'} type="button" onClick={() => {
          setShowConfirmation(current => !current);
        }}>Bokningsbekräftelse</button>
      </div>

      {showConfirmation && (
        <BookingConfirmation
        apartmentNumber={activePropertyId}
        fromDate="10/8 2025"
        fromTime="15.00"
        toDate="17/8 2025"
        toTime="12.00"
        maxPersons={2}
        bookingDate="2024-10-20"
        bookingNumber="291"
        customer={{
          name: 'Susanne Cliffoord',
          address: 'Ågatan 50',
          postalCodeCity: '813 332 Hofors',
          email: 'Muntebo50@gmail.com',
          phone: '0706-9662910',
        }}
        includes={{
          cancellationProtection: false,
          linenAndTowels: true,
          cleaning: false,
        }}
        prices={{
          total: 5100,
          downPayment: 2000,
          downPaymentDueDate: '31/10 2024',
          finalPayment: 3100,
          finalPaymentDueDate: '10/7 2025',
        }}
      />
      )}

      {!showConfirmation && (
        <div className="detailed_week_view__create_booking">
          <BookingForm
            numberOfWeekOptions={availableWeeks.length > 0 ? availableWeeks : [1, 2, 3, 4]}
            preloadedFormData={preloadedFormData}
          />
        </div>
      )}
    </div>
  )
}
