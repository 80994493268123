const VIEWS = {
  RESERVATIONS: {
    label: 'Bokningar',
    value: 'RESERVATIONS',
  },
  PROPERTIES: {
    label: 'Lägenheter',
    value: 'PROPERTIES',
  },
  OVERVIEW: {
    label: 'Översikt',
    value: 'OVERVIEW',
  },
}

export { VIEWS }
