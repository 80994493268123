const BookingConfirmation = ({
  apartmentNumber,
  fromDate,
  fromTime,
  toDate,
  toTime,
  maxPersons,
  bookingDate,
  bookingNumber,
  customer,
  includes,
  prices,
}) => {
  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px',
        color: '#000',
      }}
    >
      <table
        width="100%"
        style={{ borderCollapse: 'collapse', border: '1px solid black' }}
      >
        <tbody>
          <tr>
            <td
              style={{
                width: '50%',
                padding: '10px',
                borderRight: '1px solid black',
              }}
            >
              <strong>RINGARENS LÄGENHETSHOTELL</strong>
              <br />
              Vårdklockegatan 6-8
              <br />
              621 57 Visby
              <br />
              Tel: 0762-787701
              <br />
              mail: info@uthyrningvisby.se
              <br />
              <br />
              VI TACKAR FÖR DIN BESTÄLLNING
              <br />
              OCH HÄLSAR DIG VÄLKOMMEN
              <br />
              TILL VISBY
              <br />
              <br />
              Lägenhet med adress:
              <br />
              Lägenhet nr {apartmentNumber} Vårdklockegatan 8<br />
              <br />
              Från {fromDate} <strong>kl. {fromTime}</strong>
              <br />
              Till {toDate} <strong>kl. {toTime}</strong>
              <br />
              <br />
              Nyckel hämtas och lämnas på Vårdklockegatan 6<br />
              Hämtning av nycklar mellan 15.00 – 16.00
              <br />
              För nyckelservice annan tid ring 0762-787701
              <br />
              <br />
              Antal personer maximalt {maxPersons}
              <br />
              Husdjur får ej medtagas
            </td>
            <td style={{ width: '50%', padding: '10px' }}>
              Bokningsdatum {bookingDate}
              <br />
              Bokningsnr. {bookingNumber}
              <br />
              <br />
              <strong>Beställarens namn, adress och telefon nr</strong>
              <br />
              {customer.name}
              <br />
              {customer.address}
              <br />
              {customer.postalCodeCity}
              <br />
              {customer.email}
              <br />
              Tel {customer.phone}
              <br />
              <br />
              Pris för angivet antal personer {prices.total}kr
              <br />
              <br />
              Avbeställningsskydd:{' '}
              {includes.cancellationProtection ? 'ingår' : 'ingår ej'}
              <br />
              Linne och handdukar:{' '}
              {includes.linenAndTowels ? 'ingår' : 'ingår ej'}
              <br />
              Slutstädning: {includes.cleaning ? 'ingår' : 'ingår ej'}
              <br />
              <br />
              Totalt pris: {prices.total}kr
              <br />
              <br />
              <strong>Anmälningsavgift: {prices.downPayment}kr</strong>
              <br />
              Anmälningsavgiften bet senast {prices.downPaymentDueDate}
              <br />
              <br />
              <strong>SLUTLIKVID: {prices.finalPayment}kr</strong>
              <br />
              SLUTLIKVID betalas senast den {prices.finalPaymentDueDate}
              <br />
              <br />
              RINGARENS BANKGIRO 226–8894 (Snögrinde Invest AB)
              <br />
              Märk betalning med namn, hyresperiod och boknings nummer.
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{ marginTop: '20px', fontSize: '13px' }}>
        <strong>Viktig information inför din resa till Visby</strong>
        <br />
        <br />
        <strong>TILLTRÄDE, VISTELSE OCH AVRESA</strong>
        <br />
        Du disponerar bokad lägenhet med självhushåll den tid som anges på
        bokningsbekräftelsen. Sänglinne och handdukar ingår inte, men går att
        hyra för 250:- per set, beställes innan ankomst. Ordentlig städning av
        lägenheten utföres av gästen, varefter lägenheten återgår till
        hyresvärden för avsyning senast 11.00 avresedagen. Slutstädning går att
        beställa vid ankomst.
        <br />
        <br />
        <u>Reklamation</u> Ev. anmärkningar görs inom 24 timmar på tel:
        0762-787701
        <br />
        <br />
        <u>Avbeställning</u>
        <br />
        Hyresgästen har rätt att avbeställa objektet före tillträdesdagen. Vid
        avbeställning tidigare än 40 dagar före tillträdesdagen utgår ingen
        ersättning till uthyraren utöver anmälningsavgiften. ... (lägg in hela
        texten här om du vill)
        <br />
        <br />
        Vi önskar er en trevlig vistelse i Visby och på Gotland!
        <br />
        Veronica och Henrik
      </div>
    </div>
  );
};

export default BookingConfirmation;